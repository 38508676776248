import React from 'react';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import VisibilityViewerIcon from 'js/components/Common/VisibilityViewerIcon/VisibilityViewerIcon';

import classes from './LabelsRow.module.pcss';

export interface Props {
    pageId?: number,
    category?: string,
    readTime?: number,
    watchTime?: string,
    views?: number,
    lastUpdated?: string
}

const LabelsRow = (
    {
        views,
        pageId,
        readTime,
        watchTime,
        lastUpdated
    }: Props) => (
    <div className={classes.LabelsRow}>
        <DataValidator data={pageId}>
            <VisibilityViewerIcon id={pageId} additionalClass={classes.VisibilityIcon}/>
        </DataValidator>
        <div className={classes.Wrap}>
            <DataValidator data={readTime}>
                <span className={classes.Time}>{readTime} {readTime > 1 ? 'Mins' : 'Min'}</span>
            </DataValidator>
            <DataValidator data={watchTime}>
                <span className={classes.Time}>{watchTime}</span>
            </DataValidator>
            <DataValidator data={views}>
                <span className={classes.Views}>{views} {views > 1 ? 'Views' : 'View'}</span>
            </DataValidator>
            <DataValidator data={lastUpdated?.length > 0}>
                <span className={classes.LastUpdate}>Last Updated {lastUpdated}</span>
            </DataValidator>
        </div>
    </div>
);

export default LabelsRow;

