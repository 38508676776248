import React from 'react';

import Video from '../Entities/Entity/Video/Video';
import Course from '../Entities/Entity/Course/Course';
import Manual from '../Entities/Entity/Manual/Manual';
import Article from '../Entities/Entity/Article/Article';


const SearchResultsItem = ({type, data, isPromoted}) => {
    switch (type) {
        case 'articles' :  return <Article data={data} isPromoted={isPromoted}/>;
        case 'manuals' :  return <Manual data={data} isPromoted={isPromoted}/>;
        case 'courses' :  return <Course data={data} isPromoted={isPromoted}/>;
        case 'videos' :  return <Video data={data} isPromoted={isPromoted}/>;
        default : return null;
    }
};

export default SearchResultsItem;
