import React from 'react';
import PreLoginTop from 'js/components/Templates/PreLoginPage/PreLoginTop/PreLoginTop';
import WhatWeDo from 'js/components/Templates/PreLoginPage/WhatWeDo/WhatWeDo';
import Products from 'js/components/Templates/PreLoginPage/Products/Products';
import LearnSection from 'js/components/Templates/PreLoginPage/LearnSection/LearnSection';
import Training from 'js/components/Templates/PreLoginPage/Training/Training';
import ManagedServicesBanner from 'js/components/Common/ManagedServicesBanner/ManagedServicesBanner';

const PreLoginPageTemplate = ({ pageId }) => (
	<main className="templatePreLogin">
		<PreLoginTop pageId={pageId} />
		<WhatWeDo pageId={pageId} />
		<Products pageId={pageId} />
		<LearnSection pageId={pageId} />
		<Training pageId={pageId} />
		<ManagedServicesBanner />
	</main>
);

export default PreLoginPageTemplate;
