const fetchData = (pageId: number): string => {
  const entityData:string = '{ count description }';
  return (
      `{
        page(id: "${pageId}", idType: DATABASE_ID) {
          PreLoginPage {
            titleHykl
            descriptionHykl
            articlesHykl ${entityData}
            manualsHykl ${entityData}
            videosHykl ${entityData}
            coursesHykl ${entityData}
          }
        }
  }`
  );
};
export default fetchData;
