const fetchData:string = `
    {
    themeGeneralSettings {
      Options {
        signupPopup {
          signUpPopupDescription
          loginMessage
          contactMessage
          contactButton {
            url
            title
          }
        }
      }
    }
  }
`;
export default fetchData;
