import React, {useContext, useCallback, useMemo, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';
import PopUp from 'js/components/Common/PopUp/PopUp';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import { CommonContext } from 'js/context/CommonContext/CommonContext';
import OneLoginLogo from 'images/ol.png';

import fetchData from './fetchData';
import HeaderButtons from './HeaderButtons/HeaderButtons';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import classes from './Header.module.pcss';
import parse from 'html-react-parser';
import Image from 'js/components/Common/Image/Image';

type SignUpPopupDataType = {
	signUpPopupDescription: string,
	loginMessage: string,
	contactMessage: string,
	contactButton: {
		url: string,
		title: string
	}
}

const HeaderPreLogin = () => {
	const [signUpPopup, setSignUpPopup] = useState<boolean>(false);
	const history = useHistory();
	const {pathname, search} = useLocation();
	const {isCustomerLogged, apiPath} = useContext(CommonContext);
	const [signUpPopupData, setSignUpPopupData] = useState<SignUpPopupDataType>(null);

	const searchParams = useMemo(() => new URLSearchParams(search), [search]);

	const { isLoading, error } = useFetchData({
	    query: `${fetchData}`,
	    condition: true,
	    updateData: (data) => {
			const result = data?.themeGeneralSettings?.Options;
			setSignUpPopupData(result?.signupPopup);
	    }
	});
	useEffect(() => {
		if (isCustomerLogged && searchParams.get('sourcePage')) {
			searchParams.delete('sourcePage');
			history.replace({
				search: searchParams.toString()
			});
		}
	}, [isCustomerLogged, searchParams, history]);

	const logInHandler = useCallback(() => {
		if (pathname === '/') {
			const sourcePage = searchParams.get('sourcePage');
			return window.location.href = sourcePage ? `${apiPath}/auth?sourcePage=${sourcePage}` : `${apiPath}/auth`;
		}
		return window.location.href = `${apiPath}/auth?sourcePage=${pathname}`;
	}, [apiPath, pathname, searchParams]);

	const signUpHandler = useCallback(() => setSignUpPopup(true), []);

	return (
		<>
			<HeaderButtons logInCallback={logInHandler} signUpCallback={signUpHandler}/>
			<DataValidator data={!isLoading && !error}>
				<PopUp
					type={'S'}
					heading={''}
					popUpTrigger={signUpPopup}
					popUpClose={() => setSignUpPopup(false)}>
					<div className={classes.PopupWrap}>
						<figure className={classes.PopupImage}>
							<Image mediaItemUrl={OneLoginLogo} alt={'One Login'}/>
						</figure>
						<div className={classes.PopupInfo}>
							{parse(signUpPopupData?.signUpPopupDescription || '')}
						</div>
						<p className={classes.PopupMessage}>
							{parse(signUpPopupData?.contactMessage || '')}
							<a href={signUpPopupData?.contactButton?.url}>{signUpPopupData?.contactButton?.title}</a>
						</p>
						<p className={classes.PopupMessage}>
							{parse(signUpPopupData?.loginMessage || '')}
							<button onClick={logInHandler}>Login</button>
						</p>
					</div>
				</PopUp>
			</DataValidator>
		</>
	);
};

export default HeaderPreLogin;
