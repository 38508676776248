import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import Label from 'js/components/Common/Label/Label';
import Heading from 'js/components/Common/Heading/Heading';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import ReadingTime from 'js/containers/Pages/Search/SearchResults/Entities/components/ReadingTime/ReadingTime';

import classes from './RecommendedArticlesComponent.module.pcss';
import { RecommendedArticlesComponentDataInterface } from './RecommendedArticlesComponentDataInterface';

const RecommendedArticlesComponent = (props:RecommendedArticlesComponentDataInterface) => {
	const history = useHistory();
	return (
		<DataValidator data={props.recommendedArticles}>
			<div className={classes.RecommendedArticles}>
				<Heading text={'Related Articles'} type={'h5'} />
				<div className={classes.RecommendedArticlesItems}>
					{props?.recommendedArticles?.map(item =>
						(
							<Link
								to={{
									pathname: item.link,
									state: history?.location?.state
								}}
								className={classes.RecommendedArticlesItem} key={item.id}>
								<div className={classes.Info}>
									<Heading text={`${item.title}`} type={'h5'} />
									<div className={classes.InfoWrap}>
										<Label value={item.category} newClass={classes.Category}/>
										<ReadingTime data={item?.readTime} additionalClass={classes.ReadTime}/>
									</div>
								</div>
							</Link>
						)
					)}
				</div>
			</div>
		</DataValidator>
	);
};

export default RecommendedArticlesComponent;
