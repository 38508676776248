import React, {useMemo, useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import {ImageBlock} from 'js/components/Common/Image/ImageBlockDataInterface';

import fetchData from './fetchData';
import classes from './WhatWeDo.module.pcss';
import Container from 'js/components/Common/Container/Container';
import Heading from 'js/components/Common/Heading/Heading';
import Image from 'js/components/Common/Image/Image';

interface Props {
    pageId: number
}

type ListType = {
	title: string,
	description: string,
	icon: ImageBlock
}

const WhatWeDo = ({pageId}: Props) => {
    const [title, setTitle] = useState<string>('');
    const [list, setList] = useState<Array<ListType>>([]);

    const {isLoading, error} = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data: any): void => {
			setTitle(data?.page?.PreLoginPage?.titleWwd);
			setList(data?.page?.PreLoginPage?.list);
        }
    });

	const descriptionHeight = useMemo(() => list?.length < 3 ? 72 : 92, [list]);

    return (
        <DataValidator data={!isLoading && !error && list?.length > 0}>
            <section className={classes.WhatWeDo}>
				<Container>
					<Heading text={title} />
					<div className={classes.List}>
						{list?.map((item, index) => (
							<div key={index} className={classes.ListItem}>
								<figure>
									<Image mediaItemUrl={item?.icon?.mediaItemUrl} alt={item?.title} />
								</figure>
								<Heading text={item?.title} type={'h4'} />
								<p style={{
									height: `${descriptionHeight}px`
								}}>{item?.description}</p>
							</div>
						))}
					</div>
				</Container>
			</section>
		</DataValidator>
	);
};

export default WhatWeDo;
