class NavigationService {
    setSubMenuPosition(ref) {
        if (!ref.current) {
            return;
        }
        const subMenu = ref.current;
        const anchorRect = subMenu.parentElement.previousElementSibling?.getBoundingClientRect();
        const liRect = subMenu.parentElement.parentElement.getBoundingClientRect();
        const leftOffset = anchorRect.left - liRect.left - 18;
        subMenu.parentElement.style.left = `${leftOffset}px`;

        if (subMenu.scrollHeight > subMenu.clientHeight) {
            subMenu.parentElement.classList.add('withScroll');
        } else {
            subMenu.parentElement.classList.remove('withScroll');
        }
    }
    setPositionForSingleLabel(span) {
        const siblingSpan = span?.nextElementSibling;
        const siblingRect = siblingSpan.getBoundingClientRect();
        const liRect = siblingSpan.parentElement.getBoundingClientRect();
        const leftOffset = siblingRect.left - liRect.left;
        span.style.left = `${leftOffset}px`;
    }
    setComingSoonLabelsPosition(ref) {
        if (!ref.current) {
            return;
        }

        const menu = ref.current;
        const comingSoonSpans = menu.querySelectorAll('li > span[class*="ComingSoonLabel"]');

        if (!comingSoonSpans?.length) {
            return;
        }

        for (const span of comingSoonSpans) {
            this.setPositionForSingleLabel(span);
        }
    }
}

export default new NavigationService();
