import {makeObservable, observable, action} from 'mobx';

export type FilterTaxDataType = {
    suitableFor: string,
    skills: string,
    productLegacy: string,
    language: string,
    articleType: string,
    courseType: string,
    eventType: string,
    videoType: string,
    podcastType: string,
    presentationType: string,
    manualsType: string,
    subjectMatter: string
};

type SourcePageType = {
    name: string,
    uri: string,
    parent: {
        name: string,
        uri: string
    }
};

type CountType = {
    name: string,
    count: number
};

type countsType = {
    suitableFor: Array<CountType>,
    skills: Array<CountType>,
    productLegacy: Array<CountType>,
    subjectMatter: Array<CountType>,
    language: Array<CountType>,
    articleType: Array<CountType>,
    courseType: Array<CountType>,
    eventType: Array<CountType>,
    videoType: Array<CountType>,
    podcastType: Array<CountType>,
    presentationType: Array<CountType>,
    manualsType: Array<CountType>
};

class SearchStore {
    sourcePage:SourcePageType = {
        name: '',
        uri: '',
        parent: {
            name: '',
            uri: ''
        }
    };
    isSearchTransferData: boolean = false;
    showSearch:boolean = false;
    searchStatus:boolean = false;
    autoCompleteSpinnerStatus:boolean = false;
    searchPage:number = 1;
    searchQuery:string = '';
    orderBy:string = 'mostRelevant';
    filterEntitiesData:'articles' | 'courses' | 'manuals' | 'meetings' | 'podcasts' | 'videos' | 'presentations' | '' = '';
    entitiesCounts: Array<any> = [];
    filterTaxData:FilterTaxDataType = {
        suitableFor: '',
        skills: '',
        productLegacy: '',
        language: '',
        articleType: '',
        courseType: '',
        eventType: '',
        videoType: '',
        podcastType: '',
        presentationType: '',
        manualsType: '',
        subjectMatter: ''
    };

    taxCounts:countsType = {
        suitableFor: [],
        skills: [],
        productLegacy: [],
        subjectMatter: [],
        language: [],
        articleType: [],
        courseType: [],
        eventType: [],
        videoType: [],
        podcastType: [],
        presentationType: [],
        manualsType: []
    };

    typesTaxSections = ['articleType', 'courseType', 'presentationType', 'videoType', 'manualsType'];
    entityTypes = {
        articleType: 'articles',
        courseType: 'courses',
        presentationType: 'presentations',
        videoType: 'videos',
        manualsType: 'manuals'
    };

    constructor() {
        makeObservable(this, {
            sourcePage: observable,
            isSearchTransferData: observable,
            typesTaxSections: observable,
            entityTypes: observable,
            showSearch: observable,
            autoCompleteSpinnerStatus: observable,
            orderBy: observable,
            searchPage: observable,
            searchQuery: observable,
            filterTaxData: observable,
            searchStatus: observable,
            filterEntitiesData: observable,
            entitiesCounts: observable,
            taxCounts: observable,
            setSearchQuery:action,
            setFilterEntitiesData:action,
            setFilterTaxData:action,
            setEntitiesCounts:action,
            setOrderBy:action,
            setSearchStatus:action,
            setTaxCounts:action,
            setSearchPage:action,
            setIsSearchTransferData:action,
            setAtoCompleteSpinnerStatus:action
        });
    }

    setSourcePage(value: SourcePageType) {
        this.sourcePage = value;
    }

    setAtoCompleteSpinnerStatus(value:boolean) {
        this.autoCompleteSpinnerStatus = value;
    }

    setShowSearch(value:boolean) {
        this.showSearch = value;
    }

    setSearchPage(value:number) {
        this.searchPage = value;
    }

    setSearchQuery(value: string) {
        this.searchQuery = value;
    }

    setFilterEntitiesData(value) {
        this.filterEntitiesData = value;
    }
    setFilterTaxData(value) {
        this.filterTaxData = value;
    }

    setEntitiesCounts(value) {
        this.entitiesCounts = value;
    }

    setOrderBy(value) {
        this.orderBy = value;
    }

    setSearchStatus(value) {
        this.searchStatus = value;
    }

    setTaxCounts(value) {
        this.taxCounts = value;
    }

    setIsSearchTransferData(value) {
        this.isSearchTransferData = value;
    }

    setSearchTransferData(data) {
        const tax = {...this.filterTaxData};
        this.isSearchTransferData = true;
        this.filterEntitiesData = data?.entity;
        if (data?.parent) {
            tax.productLegacy = `${data?.parent}`;
            tax.subjectMatter = `${data?.id}`;
        } else {
            tax.productLegacy = `${data?.id}`;
        }
        this.filterTaxData = tax;
        this.searchQuery = data?.query;
    }
}
export default new SearchStore();
