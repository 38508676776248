const manualFetchData = `
    ... on ManualsSearch {
          id
          type
          title
          description
          downloads
          language
          file
          isPromoted
          publishingDate
          thumbnail
          isRelease
        }
    `;
export default manualFetchData;

export const updateDownloadsQueryManual = id => (
    `
    {
        manualsSingle(id: "${id}", idType: DATABASE_ID) {
          updateDownloads
        }
    }
    `
);
