import React, {useContext, useState, useRef, useCallback} from 'react';
import {useLocation} from 'react-router';
import parse from 'html-react-parser';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Feedback from 'js/components/Common/Feedback/Feedback';
import Container from 'js/components/Common/Container/Container';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import TeamProfile from 'js/components/Common/TeamProfile/TeamProfile';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import AttachedVideo from 'js/components/Common/AttachedVideo/AttachedVideo';
import RecommendedArticlesComponent from 'js/components/Common/RecommendedArticlesComponent/RecommendedArticlesComponent';

import Content from './Content/Content';
import Voting from './Content/Voting/Voting';
import classes from './SingleArticles.module.pcss';
import {fetchData, attachedVideoFetchData} from './fetchData';
import MoreInfoBlock from './Content/MoreInfoBlock/MoreInfoBlock';
import {SingleArticleDataInterface} from './SingleArticleDataInterface';
import AttachedManuals, {ManualDataType} from './Content/AttachedManuals/AttachedManuals';
import LabelsRow from 'js/components/Common/LabelsRow/LabelsRow';
import ProductLabelsList from 'js/components/Common/ProductLabelList/ProductLabelsList';

const SingleArticles = ({pageId}) => {
    const eventCategory:string = 'Article Single';
    const [postData, setPostData] = useState<SingleArticleDataInterface>({});
    const [manualData, setManualData] = useState<Array<ManualDataType>>(null);
    const [feedbackTitle, setFeedbackTitle] = useState<string>('');
    const [attachedVideoData, setAttachedVideoData] = useState<{link: string, preview: any}>(null);
    const {apiPath} = useContext(CommonContext);
    const nodeRef = useRef(null);
    const location = useLocation();

    const { isLoading, error } = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data) => {
            const result = data?.articlesSingle;
            setPostData(result);
            setManualData(result?.Articles?.attachedManuals);
            setFeedbackTitle(data?.monday?.Monday?.feedback?.articleFeedbackTitle || '');
        }
    });

    const sendMixPanelPlayEvent = useCallback( async (title: string) => {
        await MixPanelWrapper.TrackEvent(
            'Play Video',
            {
                category: eventCategory,
                value: `${title}`
            });
    }, []);

    const sendMixPanelFeedbackEvent = useCallback( async (title: string) => {
        await MixPanelWrapper.TrackEvent(
            'Feedback Section is sent',
            {
                category: eventCategory,
                value: `${title}`
            });
    }, []);

    const sendMixPanelVoteEvent = useCallback( async (title: string, selected: 'Yes' | 'No') => {
        await MixPanelWrapper.TrackEvent(
            'Was this helpful is chosen',
            {
                category: eventCategory,
                value: `${title}`,
                selected
            });
    }, []);

    useFetchData({
        query: `${attachedVideoFetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data) => {
            const result = data?.articlesSingle?.Articles;
            if (!result) {
                return;
            }
            setAttachedVideoData({
                link: result?.attachedVideo?.videoLink,
                preview: result?.attachedVideo?.videoPreview
            });
        }
    });

    return (
        <DataValidator data={!isLoading && !error}>
            <main ref={nodeRef} className={classes.SingleArticle}>
                <Container>
                    <article className={classes.ArticleContent}>
                        <LabelsRow
                            pageId={pageId}
                            views={postData?.articleViews}
                            category={postData?.articleCategoryList?.nodes[0]?.name}
                            readTime={postData?.readTime}
                            lastUpdated={postData?.lastEditedAt}
                        />
                        <ProductLabelsList pageId={pageId} />
                        <Heading type={'h1'} text={parse(postData?.title || '')} />
                        <TeamProfile
                            type={'Big'}
                            additionalClass={classes.TeamProfile}
                            title={postData?.Articles?.articleAuthor?.title}
                            position={postData?.Articles?.articleAuthor?.Team.position}
                            photo={postData?.Articles?.articleAuthor?.featuredImage} />
                        <Content data={postData?.Articles?.articleContent} nodeRef={nodeRef}/>
                        {attachedVideoData?.link && attachedVideoData?.preview ?
                            <AttachedVideo
                            title={postData?.title}
                            sendEventHandler={async (value:string) => await sendMixPanelPlayEvent(value)}
                            link={attachedVideoData?.link}
                            preview={attachedVideoData?.preview}
                        /> : null}
                        <AttachedManuals manuals={manualData} />
                        <div className={classes.FeedbackWrap}>
                            <Feedback
                                id={pageId}
                                label={feedbackTitle}
                                title={postData?.title}
                                eventHandler={async (value:string) => await sendMixPanelFeedbackEvent(value)}
                                link={`${apiPath}${location?.pathname}`}
                            />
                            <Voting
                                title={postData?.title}
                                eventHandler={
                                async (value:string, selected: 'Yes' | 'No') =>
                                    await sendMixPanelVoteEvent(value, selected)
                                }
                            />
                        </div>
                        <MoreInfoBlock type={'furtherAssistanceInfo'} border />
                        <RecommendedArticlesComponent recommendedArticles={postData?.recommendedArticles}/>
                    </article>
                </Container>
            </main>
        </DataValidator>
    );
};

export default SingleArticles;
