import React, {useMemo} from 'react';
import Holder from 'images/holder.png';
import Image from 'js/components/Common/Image/Image';
import VideoIcon from 'images/entities/icons/videos.svg';
import CourseIcon from 'images/entities/icons/courses.svg';
import ManualIcon from 'images/entities/icons/manuals.svg';
import ArticleIcon from 'images/entities/icons/articles.svg';
import ReleaseUpdateIcon from 'images/entities/icons/release_updates.svg';

import classes from './Thumbnail.module.pcss';
export interface Props {
    type?: 'articles' | 'videos' | 'manuals' | 'courses' | 'releaseUpdates',
    alt?: string,
    additionalClass?: string,
    sizeType?: 'S' | 'M' | 'L'
}

const Thumbnail = ({type = 'articles', alt = '', sizeType = 'S',  additionalClass = ''}:Props) => {
    const icon:string = useMemo(():any => {
        switch (type) {
            case 'articles' : return ArticleIcon;
            case 'videos' : return VideoIcon;
            case 'manuals' : return ManualIcon;
            case 'courses' : return CourseIcon;
            case 'releaseUpdates' : return ReleaseUpdateIcon;
            default : return Holder;
        }
    }, [type]);

    return (
        <figure className={`${classes.Thumbnail} ${additionalClass}`} datatype={type} data-size={sizeType}>
            <Image mediaItemUrl={icon} alt={alt} />
        </figure>
    );

};

export default Thumbnail;
