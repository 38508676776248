import React, {useCallback, useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './PreLoginTop.module.pcss';
import AttachedVideo from 'js/components/Common/AttachedVideo/AttachedVideo';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import {ImageBlockDataInterface} from 'js/components/Common/Image/ImageBlockDataInterface';
import parse from 'html-react-parser';
import Container from 'js/components/Common/Container/Container';

interface Props {
    pageId: number
}

const PreLoginTop = ({pageId}: Props) => {
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [video, setVideo] = useState<{
        videoLink: string,
        videoPreview: ImageBlockDataInterface
    }>(null);

    const {isLoading, error} = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data: any): void => {
            setTitle(data?.page?.PreLoginPage?.titleTop);
            setDescription(data?.page?.PreLoginPage?.descriptionTop);
            setVideo(data?.page?.PreLoginPage?.attachedVideo);
        }
    });

    const sendMixPanelPlayEvent = useCallback(async (heading:string) => (
        await MixPanelWrapper.TrackEvent(
            'Play Video',
            {
                category: 'PreLogin Page',
                value: heading
            })
    ), []);


    return (
        <DataValidator data={!isLoading && !error && video && description?.length > 0 && title?.length > 0}>
            <section className={classes.PreLoginTop}>
                <Container additionalClass={classes.Wrap}>
                    <div className={classes.Info}>
                        {parse(title || '')}
                        {parse(description || '')}
                    </div>
                    <DataValidator data={video?.videoLink && video?.videoPreview}>
                        <AttachedVideo
                            title={title}
                            link={video?.videoLink}
                            preview={video?.videoPreview}
                            sendEventHandler={async (value: string) => await sendMixPanelPlayEvent(value)}
                            customClass={classes.VideoFrame}
                            withoutInfo={true}
                            customVideoSize={{
                                width: '700',
                                height: '392'
                            }}
                        />
                    </DataValidator>
                </Container>
            </section>
        </DataValidator>
    );
};

export default PreLoginTop;
