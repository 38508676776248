import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import Button from 'js/components/Common/Button/Button';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import ResourceVisibilityService from 'js/components/Common/ResourceVisibilityValidator/ResourceVisibilityService';

import Tabs from './Tabs/Tabs';
import Items from './Items/Items';
import fetchData from './fetchData';
import classes from './ProductContent.module.pcss';
import ProductContentService from './ProductContentService';
import {ContentDataItemType, ContentDataType} from './Items/DataTypes';


export type ContentType = 'videosContent' | 'articlesContent' | 'coursesContent' | 'manualsContent' | 'releaseUpdatesContent';

interface Props {
    pageId: number
}

const ProductContent = ({pageId}: Props) => {
    const {apiPath} = useContext(CommonContext);
    const [activeTab, setActiveTab] = useState<ContentType | string>('');
    const [title, setTitle] = useState<string>('');
    const [pageTitle, setPageTitle] = useState<string>('');
    const [productCustomData, setProductCustomData] = useState<ContentDataType>(null);
    const [productInitData, setProductInitData] = useState<any>(null);
    const [parent, setParent] = useState<number>(null);

    const { isLoading, error } = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: async (data) => {
            setTitle(data?.product?.ProductGlobal?.contentTitleProduct);
            const customDaTa = data?.productLegacy?.ProductLegacyFields?.content;
            const {videosContent, articlesContent, coursesContent, manualsContent} = data?.productLegacy || {};
            const {releaseUpdatesContent} = data || {};

            setParent(data?.productLegacy?.parent?.termTaxonomyId);
            setPageTitle(data?.productLegacy?.name);

            customDaTa.videosContent.items = await ResourceVisibilityService.filterArrayByVisibility(customDaTa.videosContent?.items, 'databaseId', apiPath);
            customDaTa.videosContent.onFocusItem = await ResourceVisibilityService.filterItemByVisibility(customDaTa.videosContent?.onFocusItem, 'databaseId', apiPath);

            customDaTa.articlesContent.items = await ResourceVisibilityService.filterArrayByVisibility(customDaTa.articlesContent?.items, 'databaseId', apiPath);
            customDaTa.articlesContent.onFocusItem = await ResourceVisibilityService.filterItemByVisibility(customDaTa.articlesContent?.onFocusItem, 'databaseId', apiPath);

            customDaTa.coursesContent.items = await ResourceVisibilityService.filterArrayByVisibility(customDaTa.coursesContent?.items, 'databaseId', apiPath);
            customDaTa.coursesContent.onFocusItem = await ResourceVisibilityService.filterItemByVisibility(customDaTa.coursesContent?.onFocusItem, 'databaseId', apiPath);

            customDaTa.manualsContent.items = await ResourceVisibilityService.filterArrayByVisibility(customDaTa.manualsContent?.items, 'databaseId', apiPath);
            customDaTa.manualsContent.onFocusItem = await ResourceVisibilityService.filterItemByVisibility(customDaTa.manualsContent?.onFocusItem, 'databaseId', apiPath);

            customDaTa.releaseUpdatesContent.items = await ResourceVisibilityService.filterArrayByVisibility(customDaTa.releaseUpdatesContent?.items, 'databaseId', apiPath);
            customDaTa.releaseUpdatesContent.onFocusItem = await ResourceVisibilityService.filterItemByVisibility(customDaTa.releaseUpdatesContent?.onFocusItem, 'databaseId', apiPath);

            videosContent.items = await ResourceVisibilityService.filterArrayByVisibility(videosContent?.items, 'databaseId', apiPath);
            articlesContent.items = await ResourceVisibilityService.filterArrayByVisibility(articlesContent?.items, 'databaseId', apiPath);
            coursesContent.items = await ResourceVisibilityService.filterArrayByVisibility(coursesContent?.items, 'databaseId', apiPath);
            manualsContent.items = await ResourceVisibilityService.filterArrayByVisibility(manualsContent?.items, 'databaseId', apiPath);
            releaseUpdatesContent.articles.items = await ResourceVisibilityService.filterArrayByVisibility(releaseUpdatesContent?.articles?.items, 'databaseId', apiPath);
            releaseUpdatesContent.manuals.items = await ResourceVisibilityService.filterArrayByVisibility(releaseUpdatesContent?.manuals?.items, 'databaseId', apiPath);

            const releaseItems = [...releaseUpdatesContent?.articles?.items, ...releaseUpdatesContent?.manuals?.items];

            setProductCustomData(customDaTa);
            setProductInitData(
                {
                    videosContent,
                    articlesContent,
                    coursesContent,
                    manualsContent,
                    releaseUpdatesContent : {
                        items: releaseItems
                    }
                });
        }
    });

    const tabList = useMemo(() =>
        ProductContentService.getTabList(productCustomData, productInitData), [productCustomData, productInitData]);

    useEffect(() => {
        if (activeTab || !tabList?.length) {
            return;
        }
        setActiveTab(tabList[0]?.name);
    }, [tabList, activeTab]);

    const getEntityValueForEvents = useCallback(val =>
        ProductContentService.getMixPanelEntityData(val), []);

    const setActiveTabHandler = useCallback(async (val) => {
        setActiveTab(val);
        await MixPanelWrapper.TrackEvent(
            'Entity button is clicked on. Content section',
            ProductContentService.getMixPanelEventData(pageTitle, getEntityValueForEvents(val), parent)
        );
    }, [pageTitle, getEntityValueForEvents, parent]);
    const content:ContentDataItemType = useMemo(() =>
            ProductContentService.getActiveContent(productCustomData, productInitData, activeTab),
        [productCustomData, productInitData, activeTab]);

    const contentDataForTransfer = useMemo(() => {
        let entity = '';
        switch (activeTab) {
            case 'articlesContent' : entity = 'articles';
                break;
            case 'coursesContent' : entity = 'courses';
                break;
            case 'videosContent' : entity = 'videos';
                break;
            case 'manualsContent' : entity = 'manuals';
                break;
            case 'releaseUpdatesContent' : entity = 'articles,manuals';
                break;
            default : entity = '';
        }
        return {
            query: pageTitle,
            entity,
            id: pageId,
            parent
        };
    }, [activeTab, pageId, pageTitle, parent]);

    const viewMoreEventClickHandler = useCallback(async () => {
        await MixPanelWrapper.TrackEvent(
            '\'View More\' button is clicked on Content section',
            ProductContentService.getMixPanelEventData(pageTitle, getEntityValueForEvents(activeTab), parent)
        );
    }, [activeTab, getEntityValueForEvents, pageTitle, parent]);

    return (
        <DataValidator data={tabList?.length > 0 && !isLoading && !error}>
            <section className={classes.Content}>
                <Container>
                    <Heading text={title} type={'h2'}/>
                    <Tabs
                        activeTab={activeTab}
                        tabList={tabList}
                        setActiveTabHandler={setActiveTabHandler}
                    />
                    <Items activeTab={activeTab} parent={parent} data={content} pageTitle={pageTitle}/>
                    <div className={classes.ButtonWrap}>
                        <Button type={'link'} text={'View More'}
                                link={'/search'}
                                handler={async () => {
                                    ProductContentService.viewMoreButtonClickHandler(contentDataForTransfer);
                                    await viewMoreEventClickHandler();
                                }
                        } />
                    </div>
                </Container>
            </section>
        </DataValidator>
    );
};

export default ProductContent;
