import React, {useCallback, useMemo, useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './LearnSection.module.pcss';
import Container from 'js/components/Common/Container/Container';
import Heading from 'js/components/Common/Heading/Heading';
import parse from 'html-react-parser';
import Label from 'js/containers/Pages/Search/SearchResults/Entities/components/Label/Label';

interface Props {
    pageId: number
}

type EntityType = 'articles' | 'manuals' | 'videos' | 'courses' | ''

type EntityData = {
    count: string,
    description: string
}

type EntitiesType = {
    articles: EntityData,
    manuals: EntityData,
    videos: EntityData,
    courses: EntityData,
}

const LearnSection = ({pageId}: Props) => {
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [activeEntity, setActiveEntity] = useState<EntityType>(null);
    const [entities, setEntities] = useState<EntitiesType>({
        articles: null,
        manuals: null,
        videos: null,
        courses: null
    });

    const {isLoading, error} = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data: any): void => {
            const result = data?.page?.PreLoginPage;
            setTitle(result?.titleHykl);
            setDescription(result?.descriptionHykl);
            setEntities({
                articles: result?.articlesHykl,
                manuals: result?.manualsHykl,
                videos: result?.videosHykl,
                courses: result?.coursesHykl
            });
        }
    });

    const allEntitiesCondition = useMemo(() =>
            entities?.articles?.count?.length &&
            entities?.articles?.description?.length &&
            entities?.courses?.count?.length &&
            entities?.courses?.description?.length &&
            entities?.videos?.count?.length &&
            entities?.videos?.description?.length &&
            entities?.manuals?.count?.length &&
            entities?.manuals?.description?.length,
        [entities]);

    const itemHoverHandler = useCallback((entity:EntityType) => setActiveEntity(entity), []);

    return (
        <DataValidator data={!isLoading && !error && allEntitiesCondition}>
            <section className={classes.LearnSection}>
                <Container>
                    <Heading text={title}/>
                    <p className={classes.Description}>{parse(description || '')}</p>
                    <div className={classes.EntitiesList}>
                        <div
                            onMouseOver={() => itemHoverHandler('articles')}
                            onMouseLeave={() => setActiveEntity('')}
                            className={classes.Entity}>
                            <div className={classes.EntityWrap}>
                                <strong>Articles</strong>
                                <span>{entities?.articles?.count}</span>
                            </div>
                            <div className={classes.EntityWrap}>
                                <strong>Articles</strong>
                                <span>{entities?.articles?.count}</span>
                            </div>
                        </div>
                        <div
                            onMouseOver={() => itemHoverHandler('manuals')}
                            onMouseLeave={() => setActiveEntity('')}
                            className={classes.Entity}>
                            <div className={classes.EntityWrap}>
                                <strong>Manuals</strong>
                                <span>{entities?.manuals?.count}</span>
                            </div>
                            <div className={classes.EntityWrap}>
                                <strong>Manuals</strong>
                                <span>{entities?.manuals?.count}</span>
                            </div>
                        </div>
                        <div
                            onMouseOver={() => itemHoverHandler('videos')}
                            onMouseLeave={() => setActiveEntity('')}
                            className={classes.Entity}>
                            <div className={classes.EntityWrap}>
                                <strong>Videos</strong>
                                <span>{entities?.videos?.count}</span>
                            </div>
                            <div className={classes.EntityWrap}>
                                <strong>Videos</strong>
                                <span>{entities?.videos?.count}</span>
                            </div>
                        </div>
                        <div
                            onMouseOver={() => itemHoverHandler('courses')}
                            onMouseLeave={() => setActiveEntity('')}
                            className={classes.Entity}>
                            <div className={classes.EntityWrap}>
                                <strong>Courses</strong>
                                <span>{entities?.courses?.count}</span>
                            </div>
                            <div className={classes.EntityWrap}>
                                <strong>Courses</strong>
                                <span>{entities?.courses?.count}</span>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.EntityDescription} ${activeEntity ? classes.Active : ''}`}>
                        <Label type={activeEntity}/>
                        <p>{entities[activeEntity]?.description}</p>
                    </div>
                </Container>
            </section>
        </DataValidator>
    );
};

export default LearnSection;
