import {useCallback} from 'react';
import SearchStore from 'js/store/SearchStore';

const useSearchReset = ({nodeRef, isLocalRef = true}) => useCallback(() => {
    let filterParent = null;
    if (isLocalRef && nodeRef?.current) {
        filterParent = nodeRef?.current;
    } else if (!isLocalRef) {
        filterParent = nodeRef?.current?.parentNode;
    } else {
        return;
    }
    const allItems = filterParent?.querySelectorAll('input');
    const allSections = filterParent?.querySelectorAll('[datatype="taxonomy"], [datatype="entities"]');
    SearchStore.setFilterEntitiesData('');
    SearchStore.setFilterTaxData({
        subjectMatter: '',
        suitableFor: '',
        skills: '',
        productLegacy: '',
        language: '',
        articleType: '',
        courseType: '',
        eventType: '',
        videoType: '',
        podcastType: '',
        presentationType: '',
        manualsType: ''
    });
    SearchStore.setSearchStatus(false);

    allItems.forEach((item) => {
        item.disabled = false;
        item.checked = false;
    });
    allSections?.forEach((item) => {
        item.children[0].children[1].classList.forEach((className) => {
            if (className?.includes('Active')) {
                item.children[0].children[1].classList.remove(className);
            }
        });
        item.children[1].style.display = 'block';
    });
}, [
    nodeRef,
    isLocalRef
]);

export default useSearchReset;
