import {useLocation} from 'react-router';
import React, {useCallback, useContext, useMemo, useState} from 'react';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Feedback from 'js/components/Common/Feedback/Feedback';
import LabelsRow from 'js/components/Common/LabelsRow/LabelsRow';
import Container from 'js/components/Common/Container/Container';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import TeamProfile from 'js/components/Common/TeamProfile/TeamProfile';
import { SingleVideosDataInterface } from './SingleVideosDataInterface';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import AttachedVideo from 'js/components/Common/AttachedVideo/AttachedVideo';

import fetchData from './fetchData';
import Content from './Content/Content';
import classes from './SingleVideos.module.pcss';
import ContactInfo from './ContactInfo/ContactInfo';
import ProductLabelsList from 'js/components/Common/ProductLabelList/ProductLabelsList';


const SingleVideos = ({ pageId }) => {
	const eventCategory:string = 'Video Single';
	const location = useLocation();
	const {apiPath} = useContext(CommonContext);
	const [feedbackTitle, setFeedbackTitle] = useState<string>('');
	const [postData, setPostData] = useState<SingleVideosDataInterface>({});
	const { isLoading, error } = useFetchData({
	    query: `${fetchData(pageId)}`,
	    condition: !!pageId,
	    updateData: (data) => {
			setPostData(data?.videosSingle);
			setFeedbackTitle(data?.monday?.Monday?.feedback?.videoFeedbackTitle || '');
		}
	});

	const sendMixPanelPlayEvent = useCallback(async (title:string) => (
		await MixPanelWrapper.TrackEvent(
			'Play Video',
			{
				category: eventCategory,
				value: title
			})
	), []);

	const sendMixPanelFeedbackEvent = useCallback( async (title: string) => {
		await MixPanelWrapper.TrackEvent(
			'Feedback Section is sent',
			{
				category: eventCategory,
				value: `${title}`
			});
	}, []);

	const itemUrl:string = useMemo(() => `${apiPath}${location?.pathname}`, [apiPath, location]);
	const {title} = useMemo(() => postData, [postData]) || {};
	const video = useMemo(() => postData?.Videos?.attachedVideo, [postData]);
	const {host, videoContent, watchTime} = useMemo(() => postData?.Videos, [postData]) || {};
	const product = useMemo(() => postData?.productList?.nodes[0]?.name, [postData]);
	return (
		<DataValidator data={!isLoading && !error}>
			<main className={classes.SingleVideo}>
				<Container>
					<DataValidator data={video?.videoLink && video?.videoPreview}>
						<AttachedVideo
							title={title}
							link={video?.videoLink}
							preview={video?.videoPreview}
							sendEventHandler={async (value:string) => await sendMixPanelPlayEvent(value)}
							customClass={classes.VideoFrame}
							withoutInfo={true}
							customVideoSize={{
								width: '100%',
								height: '658'
							}}
						/>
					</DataValidator>
					<LabelsRow
						views={postData?.videoViews}
						pageId={pageId}
						category={product}
						watchTime={watchTime}
					/>
					<ProductLabelsList pageId={pageId} />
                    <Heading type={'h1'} text={title}/>
					<TeamProfile
						type={'Big'}
						title={host?.title}
						position={host?.Team?.position}
						photo={host?.featuredImage} />
					<Content data={videoContent}/>
					<div className={classes.Wrap}>
                        <Feedback
                            id={pageId}
                            label={feedbackTitle}
                            title={title}
                            link={itemUrl}
							eventHandler={async (value:string) => await sendMixPanelFeedbackEvent(value)}
                        />
						<ContactInfo />
					</div>
				</Container>
			</main>
		</DataValidator>
	);
};

export default SingleVideos;
