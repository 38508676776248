import React, {useEffect, useMemo, useState} from 'react';
import parse from 'html-react-parser';
import Swiper from 'react-id-swiper';
import Image from 'js/components/Common/Image/Image';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';
import { Navigation, Pagination } from 'swiper/js/swiper.esm';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import { ImageBlockDataInterface } from 'js/components/Common/Image/ImageBlockDataInterface';

import fetchData from './fetchData';
import classes from './Products.module.pcss';

interface Props {
    pageId: number;
}

type ProductsType = {
    description: string;
    logo: ImageBlockDataInterface;
    product: {
        id: number;
        name: string;
        uri: string;
    };
};

const Products = ({ pageId }: Props) => {
    const [slider, setSlider] = useState(null);
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [products, setProducts] = useState<Array<ProductsType>>(null);
    const [buttonLabel, setButtonLabel] = useState<string>('');
    const { isLoading, error } = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data: any): void => {
            const result = data?.page?.PreLoginPage;
            setTitle(result?.titleWykl);
            setDescription(result?.descriptionWykl);
            setProducts(result?.productsListWykl);
            setButtonLabel(data?.product?.ProductGlobal?.productReviewLabel);
        }
    });

    useEffect(() => {
        const swiperContainer = document.querySelector('.swiper-container');

        if (!swiperContainer || !slider) {
            return;
        }

        const handleMouseEnter = () => {
            slider.autoplay.stop();
        };

        const handleMouseLeave = () => {
            slider.autoplay.start();
        };

        swiperContainer.addEventListener('mouseenter', handleMouseEnter);
        swiperContainer.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            swiperContainer.removeEventListener('mouseenter', handleMouseEnter);
            swiperContainer.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, [slider]);

    const params = useMemo(
        () => ({
            slidesPerView: 'auto',
            spaceBetween: 60,
            speed: 500,
            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            modules: [Pagination, Navigation],
            pagination: {
                el: `.${classes.Slider} > .${classes.SecondWrap} > .${classes.Bullets}`,
                clickable: true,
                renderBullet: (index, className) => `<span class="${className}"></span>`
            },
            getSwiper: setSlider
        }),
        []
    );

    return (
        <DataValidator data={!isLoading && !error && products?.length > 0}>
            <section className={classes.Products}>
                <Container>
                    <Heading text={title} />
                    <p className={classes.Description}>{parse(description || '')}</p>
                </Container>
                <div className={classes.Slider}>
                    <Container additionalClass={classes.Wrap}>
                        <Swiper {...params}>
                            {products?.map((slide) => {
                                const product = slide?.product[0];
                                return (
                                    <div className={`${classes.Product} swiper-slide`} key={product?.id}>
                                        <div className={classes.Inner}>
                                            <figure>
                                                <Image mediaItemUrl={slide?.logo?.mediaItemUrl} alt={product?.name}/>
                                            </figure>
                                            <Heading text={product?.name} type={'h3'}/>
                                        </div>
                                        <div className={classes.Inner}>
                                            <p>{parse(slide?.description || '')}</p>
                                            <Button
                                                type={'a'}
                                                text={buttonLabel}
                                                link={`/auth?sourcePage=${encodeURI(`/${product?.uri}`)}`}
                                                buttonType={'ButtonColored'}
                                                buttonColorType={'Blue'}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </Swiper>
                    </Container>
                    <Container additionalClass={classes.SecondWrap}>
                        <div className={classes.Bullets}/>
                    </Container>
                </div>
            </section>
        </DataValidator>
    );
};

export default Products;
