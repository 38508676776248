import React, {ReactElement, useMemo} from 'react';
import parse from 'html-react-parser';

import headers from './Heading.module.pcss';

export interface Props {
	text: string | ReactElement | ReactElement[],
	type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
	thin?: boolean,
	colored?: boolean,
	insideEl?: any
}

const Heading = ({ text, type = 'h2', thin = false, colored = false, insideEl = '' }: Props) => {
	const thinHeadingClass = useMemo(() => thin ? headers.thinHeading : '', [thin]);
	const coloredHeadingClass = useMemo(() => colored ? headers.Colored : '', [colored]);

	return useMemo(() => {
		if (!text) {
			return null;
		}
		switch (type) {
			case 'h1' : return <h1 className={`${thinHeadingClass} ${coloredHeadingClass}`}>{typeof (text) === 'string' ? parse(text) : ''}{insideEl}</h1>;
			case 'h3' : return <h3 className={`${thinHeadingClass} ${coloredHeadingClass}`}>{typeof (text) === 'string' ? parse(text) : ''}{insideEl}</h3>;
			case 'h4' : return <h4 className={`${thinHeadingClass} ${coloredHeadingClass}`}>{typeof (text) === 'string' ? parse(text) : ''}{insideEl}</h4>;
			case 'h5' : return <h5 className={`${thinHeadingClass} ${coloredHeadingClass}`}>{typeof (text) === 'string' ? parse(text) : ''}{insideEl}</h5>;
			case 'h6' : return <h6 className={`${thinHeadingClass} ${coloredHeadingClass}`}>{typeof (text) === 'string' ? parse(text) : ''}{insideEl}</h6>;
			default   : return <h2 className={`${thinHeadingClass} ${coloredHeadingClass}`}>{typeof (text) === 'string' ? parse(text) : ''}{insideEl}</h2>;
		}
	}, [coloredHeadingClass, text, thinHeadingClass, type, insideEl]);
};
export default Heading;
