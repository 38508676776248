import React, {CSSProperties} from 'react';

import classes from './Container.module.pcss';

export interface Props {
	additionalClass?: string,
	additionalStyles?:CSSProperties,
	children?: any
}

const Container = (props: Props) => (
	<div style={props?.additionalStyles} className={`${classes.Container} ${props?.additionalClass || ''}`}>
		{props?.children}
	</div>
);

export default Container;
