import React, {useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './ProductLabel.module.pcss';
import parse from 'html-react-parser';

interface Props {
    pageId: number
}

const ProductLabelsList = ({pageId}: Props) => {
    const [list, setList] = useState<Array<{name:string}>>([]);

    const { isLoading, error } = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data:any) => setList(data?.productLabels?.productLabelsList)
    });

    return (
        <DataValidator data={!isLoading && !error && list?.length > 0}>
            <div className={classes.ProductLabelsList}>
                {list?.map((item, key) =>
                    <span key={key} className={classes.ProductLabelsListItem}>{parse(item?.name)}</span>
                )}
            </div>
        </DataValidator>
    );
};

export default ProductLabelsList;
