import imageFragment from 'js/graphqlFragments/ImageFragment';

const fetchData = (pageId: number): string => `
    {
    page(id: "${pageId}", idType: DATABASE_ID) {
      PreLoginPage {
        titleWykl
        descriptionWykl
        productsListWykl {
          description
          logo {
                ...ImageFragmentLogo
          }
          product {
              ... on ProductLegacy {
              id
              name
              uri
            }
          }
        }
      }
    }
    product {
      ProductGlobal {
        productReviewLabel
      }
    }
  }
  ${imageFragment('Logo')} 
`;
export default fetchData;
