import React, {useState, useContext} from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import { CommonContext } from 'js/context/CommonContext/CommonContext';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import { NavigationMenuItemType } from './NavigationMenuDataInterface';
import NavigationItems from '../NavigationItems/NavigationItems';

export interface Props {
	location: string,
	levels?: number,
	heading?: boolean,
	isMobile?: boolean,
}

const NavigationMenu = ({ location, levels, heading }: Props) => {
	const [menuItems, setMenuItems] = useState<Array<NavigationMenuItemType>>(null);
	const [menuClass, setMenuClass] = useState<string>('');
	const [menuHeading, setMenuHeading] = useState<string>('');
	const [loggedOnly, setLoggedOnly] = useState<boolean>(false);
	const { isCustomerLogged } = useContext(CommonContext);

	const { isLoading, error } = useFetchData({
	    query: fetchData(location, levels),
	    condition: true,
	    updateData: (data) => {
			const menus = data?.menus?.nodes;
			if (menus?.length === 1) {
				const menu = menus[0];
				setMenuClass(location);
				setMenuHeading(menu?.name);
				setLoggedOnly(menu?.FooterMenuFields?.loggedOnly);
				setMenuItems(menu?.menuItems?.nodes);
			}
	    }
	});

	return (
			<DataValidator data={!isLoading && !error && menuItems?.length > 0 }>
				<nav style={{
					display: loggedOnly && (isCustomerLogged !== null && isCustomerLogged === false) ? 'none' : 'block'
				}} className={`Navigation ${menuClass}`}>
					{heading && menuHeading ? <Heading text={menuHeading} type="h6" /> : ''}
					<NavigationItems location={location} menuItems={menuItems} isSubMenu={false}/>
				</nav>
			</DataValidator>

	);
};

export default NavigationMenu;
