import React, {useState} from 'react';
import LockIcon from 'images/lock.svg';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

import fetchData from './fetchData';
import classes from './VisibilityViewerIcon.module.pcss';
import parse from 'html-react-parser';

export interface Props {
	id: number,
	additionalClass?: string
}

const VisibilityViewerIcon = ({id,  additionalClass = ''}: Props) => {
	const [message, setMessage] = useState<string>('');
	const { isLoading, error } = useFetchData({
	    query: `${fetchData(id)}`,
	    condition: !!id,
	    updateData: data => setMessage(data?.resourceVisibility?.showVisibilityMessage)
	});

    return (
		<DataValidator data={!isLoading && !error && message?.length > 0}>
			<Tippy
					inertia={true}
					theme={'light'}
					placement={'top-start'}
					arrow={false}
				   	hideOnClick={false}
				   	followCursor={'vertical'}
				   	maxWidth={450}
				   	content={parse(message || '')}>
				<div className={`${classes.VisibilityViewerIcon} ${additionalClass}`} data-message={true}>
					<img src={LockIcon} alt={'lock icon'}/>

				</div>
			</Tippy>
		</DataValidator>
	);
};

export default VisibilityViewerIcon;
