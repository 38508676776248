import imageFragment from 'js/graphqlFragments/ImageFragment';

const fetchData = (pageId: number): string => `
{
    page(id: "${pageId}", idType: DATABASE_ID) {
      PreLoginPage {
        titleWwd
        list {
          title
          description
          icon {
            ...ImageFragmentIcon
          }
        }
      }
    }
}
${imageFragment('Icon')} 
`;
export default fetchData;
