import ImageFragment from 'js/graphqlFragments/ImageFragment';

const fetchData =  `
    {
    themeGeneralSettings {
      Options {
        bannerTitleServices
        bannerContentServices
        ctaButtonServices {
          url
          title
        }
        imageUploadServices {
          ...ImageFragmentServices
        }
      }
    }
  }
  
 ${ImageFragment('Services')}
`;
export default fetchData;
