import imageFragment from 'js/graphqlFragments/ImageFragment';

const fetchData = (pageId: number): string => `
    {
    page(id: "${pageId}", idType: DATABASE_ID) {
      PreLoginPage {
        titleTop
        descriptionTop
        attachedVideo {
              videoLink
              videoPreview {
                ...ImageFragmentVideoPreview
              }
          }
      }
    }
  }
  ${imageFragment('VideoPreview')} 
`;
export default fetchData;
