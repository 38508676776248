import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import NavigationItem from 'js/components/Common/Navigation/NavigationItem/NavigationItem';
import classes from 'js/components/Common/Header/Header.module.pcss';

import { NavigationMenuItemType } from '../NavigationMenu/NavigationMenuDataInterface';
import NavigationService from 'js/components/Common/Navigation/NavigationService';

export interface Props {
	menuItems: Array<NavigationMenuItemType>,
	isSubMenu: boolean,
	location: string
}

const NavigationItems = ({ location, menuItems, isSubMenu }:Props) => {
	const ref = useRef(null);
	const setSubMenu = useCallback((menuItem) => {
		const isSubMenuCondition = menuItem?.childItems && menuItem?.childItems?.nodes?.length > 0;
		if (!isSubMenuCondition) {
			return '';
		}
		return (
			<NavigationItems
				location={location}
				menuItems={menuItem.childItems.nodes}
				isSubMenu={isSubMenuCondition}/>
		);
	}, [location]);

	useEffect(() => {
		if (isSubMenu) {
			NavigationService.setSubMenuPosition(ref);
		}

	}, [isSubMenu]);

	useEffect(() => {
		NavigationService.setComingSoonLabelsPosition(ref);
	}, []);

	const menuList = useMemo(() => (
		<ul ref={ref}>
			{
				menuItems?.map((menuItem) => {
						let isSubMenuClass = false;
						if (menuItem?.childItems?.nodes?.length > 0) {
							isSubMenuClass = true;
						}
						return (
							<NavigationItem
								location={location}
								key={parseInt(menuItem.id)}
								item={menuItem}
								hasSubMenuClass={isSubMenuClass}
								subMenu={setSubMenu(menuItem)}>
								{menuItem.label}
							</NavigationItem>
						);
					}
				)
			}
		</ul>
	), [location, menuItems, setSubMenu]);

	return isSubMenu ? <nav className={isSubMenu ? classes.SubMenu : ''}>{menuList}</nav> : menuList;
};

export default NavigationItems;
