const fetchData = (pageId: number): string => `
    {
    page(id: "${pageId}", idType: DATABASE_ID) {
      PreLoginPage {
        titleTraining
        descriptionTraining
        stagesTitleTraining
        stagesTraining {
          label
        }
        buttonTraining {
          text
          page {
            url
          }
        }
      }
    }
  }
`;
export default fetchData;
