import React, {useMemo, useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './ManagedServicesBanner.module.pcss';
import {ImageBlock} from 'js/components/Common/Image/ImageBlockDataInterface';
import Container from 'js/components/Common/Container/Container';
import Heading from 'js/components/Common/Heading/Heading';
import parse from 'html-react-parser';
import Image from 'js/components/Common/Image/Image';
import Button from 'js/components/Common/Button/Button';

interface ButtonType {
    title: string,
    url: string
}

const ManagedServicesBanner = () => {
    const [title, setTitle] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [image, setImage] = useState<ImageBlock>(null);
    const [button, setButton] = useState<ButtonType>(null);

    const {isLoading, error} = useFetchData({
        query: `${fetchData}`,
        condition: true,
        updateData: (data: any): void => {
			const result = data?.themeGeneralSettings?.Options;
			setTitle(result?.bannerTitleServices);
			setContent(result?.bannerContentServices);
			setImage(result?.imageUploadServices);
			setButton(result?.ctaButtonServices);
        }
    });

    const pageSlug = useMemo(() => {
        if (!button?.url?.length) {
            return '';
        }
        const parts = button?.url?.split('/');
        return `/${parts[parts?.length - 1]}`;
    }, [button]);

    return (
        <DataValidator data={!isLoading && !error && content?.length > 0}>
            <section className={classes.ManagedServicesBanner}>
				<Container>
					<div className={classes.TitleWrap}>
						<Heading text={title} />
					</div>
                    <div className={classes.InfoWrap}>
                        <figure className={classes.Image}>
                            <Image
                                mediaDetails={image?.mediaDetails}
                                mediaItemUrl={image?.mediaItemUrl}
                                size={'240x240'}
                                alt={image?.altText}/>
                        </figure>
                        <div className={classes.Info}>
                            <div className={classes.InfoText}>{parse(content || '')}</div>
                            {button?.title && button?.url ?
                                <Button type={'a'}
                                       link={`/auth?sourcePage=${encodeURI(pageSlug)}`}
                                       text={button?.title} /> : ''}
                        </div>
                    </div>
                </Container>
            </section>
        </DataValidator>
    );
};

export default ManagedServicesBanner;
