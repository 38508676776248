import React, {useMemo} from 'react';
import useSearchReset from 'js/hooks/useSearchReset/useSearchReset';
import {observer} from 'mobx-react';

import classes from './SearchFilterReset.module.pcss';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import SearchStore from 'js/store/SearchStore';
import Button from 'js/components/Common/Button/Button';

const SearchFilterReset = (nodeRef) => {
    const resetClickHandler = useSearchReset(nodeRef);
    const {searchStatus, filterEntitiesData, filterTaxData} = SearchStore;
    const buttonShowCondition = useMemo(() =>
            searchStatus && filterEntitiesData?.length > 0 || Object.values(filterTaxData)?.some(value => value !== ''),
        [filterEntitiesData, filterTaxData, searchStatus]);
    return buttonShowCondition ?
        <Button
            additionalStylesClass={classes.FilterReset}
         buttonType={'ButtonColored'}
         buttonColorType={'Blue'}
         type={'button'}
         text={'Clear All'}
         handler={async () => {
             resetClickHandler();
             await MixPanelWrapper.TrackEvent(
                 'Reset search filter',
                 {
                     value: '',
                     category: 'Search Filters'
                 }
             );
         }} /> : '';
};

export default observer(SearchFilterReset);
