import React from 'react';
import Button from 'js/components/Common/Button/Button';
import classes from './HeaderButtons.module.pcss';

const HeaderButtons = ({logInCallback, signUpCallback}) => (
	<div className={classes.HeaderButtons}>
		<Button buttonColorType={'Pink'}
				buttonType={'ButtonUncolored'}
				type="button"
				text="Sign up" handler={signUpCallback}/>
		<Button buttonColorType={'Pink'} type="button" text="OneLogin" handler={logInCallback}/>
	</div>
);

export default HeaderButtons;
