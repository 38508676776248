import React, {useMemo, useState} from 'react';
import parse from 'html-react-parser';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './Training.module.pcss';

interface Props {
    pageId: number
}

type StagesType = {
    label: string
}

type ButtonDataType = {
    text: string,
    url: string
}

const Training = ({pageId}: Props) => {
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [stageTitle, setStageTitle] = useState<string>('');
    const [stages, setStages] = useState<Array<StagesType>>(null);
    const [button, setButton] = useState<ButtonDataType>(null);

    const {isLoading, error} = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data: any): void => {
            const result = data?.page?.PreLoginPage;
            console.log(result);
            setTitle(result?.titleTraining);
            setDescription(result?.descriptionTraining);
            setStageTitle(result?.stagesTitleTraining);
            setStages(result?.stagesTraining);
            setButton({
                text: result?.buttonTraining?.text,
                url: result?.buttonTraining?.page?.url
            });
        }
    });

    const pageSlug = useMemo(() => {
        if (!button?.url?.length) {
            return '';
        }
        const parts = button?.url?.split('/');
        return `/${parts[parts?.length - 1]}`;
    }, [button]);


    return (
        <DataValidator data={!isLoading && !error && stages?.length > 0}>
            <section className={classes.Training}>
                <Container additionalClass={classes.Container}>
                    <Heading text={title} />
                    <p className={classes.Description}>{parse(description || '')}</p>
                    <div className={classes.Stages}>
                        <Heading text={stageTitle} type={'h4'} />
                        <div className={classes.Items}>
                            {stages?.map((stage, index) => (
                                <div key={index} className={classes.Item}>
                                    <span>
                                        <strong>{index + 1}</strong>
                                    </span>
                                    <span>{parse(stage?.label || '')}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={classes.ButtonWrap}>
                        <Button
                            type={'a'}
                            text={button?.text}
                            link={`/auth?sourcePage=${encodeURI(pageSlug)}`}
                            buttonType={'ButtonColored'}
                            buttonColorType={'Blue'}/>
                    </div>
                </Container>
            </section>
        </DataValidator>
    );
};

export default Training;
